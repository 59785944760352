/*
  This file contains all the Javascript logic
*/

/* global mwl_data MwlIcons mwl_pro_create_deeplinking_hash mwl_pro_remove_deeplinking_hash mwl_pro_add_social_sharing */

require('./mwl-icons')
require('jquery-touchswipe')

jQuery(document).ready(function ($) {

  /**
   * The MwlImage class
   */
  class MwlImage {
    constructor(datas) {
      this.id = datas.id
      this.index = datas.index
      this.exists = datas.exists
      this.img_low_res_src = datas.img_low_res_src
      this.img_src = datas.img_src
      this.img_gps = datas.img_gps
      this.img_srcset = datas.img_srcset
      this.img_sizes = datas.img_sizes
      this.img_dimensions = datas.img_dimensions
      this.img_orientation = datas.img_orientation
      this.img_exifs = datas.img_exifs
      this.preloaded = false
    }
  }

  /**
   * The Meow Lightbox Controller
   */
  window.MwlController = function (settings) {
    this.settings = settings
    this.images_number
    this.images = []
    this.current_image_index
    this.autoplay_interval
    this.image_metadata_update_timeout

    const updateCurrentSlideCount = function (new_index) {
      $('.mwl__topbar__slide-counter .current-slide').html(parseInt(new_index) + 1)
    }

    const updateImageSizing = function (controller, index) {
      if (!controller.images) {
        console.error('Meow Lightbox: controller.images doesn\'t exist.')
        return
      }

      let image_h, image_w
      const $images = $('.mwl__slider .mwl__slider__image[data-index=' + index + '] .mwl__slider__image__thumbnail img')
      const $image_container = $('.mwl__slider .mwl__slider__image[data-index=' + index + '] .mwl__slider__image__thumbnail')

      if (controller.images[index] && controller.images[index].img_dimensions) {
        const image = controller.images[index]
        image_w = image.img_dimensions.width
        image_h = image.img_dimensions.height
      } else {
        const theImage = $('.mwl-img[mwl-index=' + index + ']')[0]
        if (theImage) {
          image_w = theImage.naturalWidth
          image_h = theImage.naturalHeight
        } else {
          return
        }
      }

      const container_h = $image_container.outerHeight()
      const container_w = $image_container.outerWidth()
      const container_ratio = container_w / container_h

      const image_ratio = image_w / image_h

      $images.css('opacity', 0)
      $images.css('width', '')
      $images.css('height', '')
      $images.css('max-width', image_w + 'px')
      $images.css('max-height', image_h + 'px')
      if (image_ratio >= container_ratio) {
        $images.css('width', '100%')
      } else {
        $images.css('height', '100%')
      }
      $images.animate({ opacity: 1 }, 300)

      window.createMwlLog('log', 'Image in lightbox has been resized.')
    }

    const updateUrl = function (controller, index) {
      if (controller.settings.deep_linking && typeof mwl_pro_create_deeplinking_hash === "function") {
        let currentUrl
        if (index === false) {
          mwl_pro_remove_deeplinking_hash()
        } else {
          currentUrl = mwl_pro_create_deeplinking_hash(controller.images[index].id)
          if (controller.settings.social_sharing && typeof mwl_pro_add_social_sharing === "function") {
            mwl_pro_add_social_sharing(currentUrl)
          }
        }
      }
    }

    const updateImageMetadata = (controller, index, initial_opening = true, callback = function () { }) => {
      const image = controller.images[index]
      const img_exifs = image.img_exifs

      let timer = 300
      if (initial_opening) {
        timer = 0
      }

      window.createMwlLog('log', 'Updating image metadata with:')
      window.createMwlLog('log', img_exifs)

      let default_markup = [
        '<h2></h2>',
        '<p class="short-description visible"></p>',
        '<p class="full-description"></p>',
        '<div class="exifs"></div>'
      ]

      default_markup = default_markup.join('')

      const $image_metadata = $('.mwl__metadata')
      $image_metadata.fadeOut(timer)

      clearTimeout(this.image_metadata_update_timeout)

      this.image_metadata_update_timeout = setTimeout(function () {
        $image_metadata.html(default_markup)

        if (img_exifs.title && controller.settings.infos_to_display.title) {
          $image_metadata.find('h2').html(img_exifs.title)
        } else {
          $image_metadata.find('h2').remove()
        }

        const maxLength = 150

        if (controller.settings.infos_to_display.caption) {
          if (controller.settings.caption_source == 'description' && img_exifs.description) {
            if (img_exifs.description.length > maxLength) {
              let trimmedString = img_exifs.description.substr(0, maxLength)
              trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
              trimmedString += '...'
              trimmedString += '<div class="toggle-full-description">' + window.MwlIcons.plus + '</div>'
              $image_metadata.find('p.short-description').html(trimmedString)
              $image_metadata.find('p.full-description').html(img_exifs.description + '<div class="toggle-full-description">' + window.MwlIcons.minus + '</div>')
            } else {
              $image_metadata.find('p.short-description').html(img_exifs.description)
              $image_metadata.find('p.full-description').remove()
            }
          }

          if (controller.settings.caption_source == 'caption' && img_exifs.caption) {
            if (img_exifs.caption.length > maxLength) {
              let trimmedString = img_exifs.caption.substr(0, maxLength)
              trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
              trimmedString += '...'
              trimmedString += '<div class="toggle-full-description">' + window.MwlIcons.plus + '</div>'
              $image_metadata.find('p.short-description').html(trimmedString)
              $image_metadata.find('p.full-description').html(img_exifs.caption + '<div class="toggle-full-description">' + window.MwlIcons.minus + '</div>')
            } else {
              $image_metadata.find('p.short-description').html(img_exifs.caption)
              $image_metadata.find('p.full-description').remove()
            }
          }
        } else {
          $image_metadata.find('p').remove()
        }

        const $exifs = $image_metadata.find('.exifs')

        if (img_exifs.camera != 'N/A' && controller.settings.infos_to_display.camera) {
          const exif_markup = '<div class="exif">' + MwlIcons.camera + ' <span>' + img_exifs.camera + '</span></div>'
          $exifs.append(exif_markup)
        }

        if (img_exifs.focal_length != 'N/A' && controller.settings.infos_to_display.focal_length) {
          const exif_markup = '<div class="exif">' + MwlIcons.eye + ' <span>' + img_exifs.focal_length + '</span></div>'
          $exifs.append(exif_markup)
        }

        if (img_exifs.shutter_speed != 'N/A' && controller.settings.infos_to_display.shutter_speed) {
          const exif_markup = '<div class="exif">' + MwlIcons.shutter_speed + ' <span>' + img_exifs.shutter_speed + '</span></div>'
          $exifs.append(exif_markup)
        }

        if (img_exifs.aperture != 'N/A' && controller.settings.infos_to_display.aperture) {
          const exif_markup = '<div class="exif">' + MwlIcons.aperture + ' <span>' + img_exifs.aperture + '</span></div>'
          $exifs.append(exif_markup)
        }

        if (img_exifs.iso != 'N/A' && controller.settings.infos_to_display.iso) {
          const exif_markup = '<div class="exif">' + MwlIcons.iso + ' <span>' + img_exifs.iso + '</span></div>'
          $exifs.append(exif_markup)
        }

        if (img_exifs.lens != 'N/A' && controller.settings.infos_to_display.lens) {
          const exif_markup = '<div class="exif">' + MwlIcons.lens + ' <span>' + img_exifs.lens + '</span></div>'
          $exifs.append(exif_markup)
        }

        if (img_exifs.date != 'N/A' && img_exifs.date != 0 && controller.settings.infos_to_display.date) {
          const exif_markup = '<div class="exif">' + MwlIcons.calendar + ' <span>' + img_exifs.date + '</span></div>'
          $exifs.append(exif_markup)
        }

        // Hiding or showing Map icon
        if (typeof MapController === "function" && controller.settings.map.enabled) {
          if (!image.img_gps || image.img_gps.lat == 'N/A' || image.img_gps.lng == 'N/A') {
            $('.mwl__topbar__controls__control--map').addClass('hidden')
          } else {
            $('.mwl__topbar__controls__control--map').removeClass('hidden')
          }
        } else {
          $('.mwl__topbar__controls__control--map').addClass('hidden')
        }

        // Hiding or showing Download icon
        if (!controller.settings.download.enabled) {
          $('.mwl__topbar__controls__control--download').addClass('hidden')
        }

        // Hiding or showing Slideshow icon
        if (!controller.settings.slideshow.enabled) {
          $('.mwl__topbar__controls__control--play').addClass('hidden')
          $('.mwl__topbar__controls__control--pause').addClass('hidden')
        }

        $image_metadata.fadeIn(timer)

        callback()
      }, timer)
    }

    /**
     * Writes the initial Lightbox markup (without images nor data)
     */
    this.writeInitialMarkup = function () {

      let thumbnails_navigation_markup = ''
      if (this.settings.thumbnails_navigation.enabled) {
        thumbnails_navigation_markup = '<div class="thumbnails-navigation"></div>'
      }

      let lightbox_markup = [
        '<div class="mwl ' + this.settings.theme + '">',
        '<div class="mwl__topbar">',
        '<div class="mwl__topbar__slide-counter">',
        '<span class="current-slide"></span> / <span class="number-of-slides">', this.images_number, '</span>',
        '</div>',
        '<div class="mwl__topbar__controls">',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--sharing">',
        window.MwlIcons.sharing,
        '<div class="mwl__topbar__controls__control--sharing__options"></div>',
        '</div>',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--download">',
        window.MwlIcons.download,
        '<div class="mwl__topbar__controls__control__label">Download Image</div>',
        '</div>',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--map">',
        window.MwlIcons.map,
        '<div class="mwl__topbar__controls__control__label">Show on map</div>',
        '</div>',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--play">',
        window.MwlIcons.play,
        '<div class="mwl__topbar__controls__control__label">Start slideshow</div>',
        '</div>',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--pause hidden">',
        window.MwlIcons.pause,
        '<div class="mwl__topbar__controls__control__label">Stop slideshow</div>',
        '</div>',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--fullscreen">',
        window.MwlIcons.fullscreen,
        '<div class="mwl__topbar__controls__control__label">Hide info</div>',
        '</div>',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--shrink hidden">',
        window.MwlIcons.shrink,
        '<div class="mwl__topbar__controls__control__label">Show info</div>',
        '</div>',
        '<div class="mwl__topbar__controls__control mwl__topbar__controls__control--close">',
        window.MwlIcons.close,
        '<div class="mwl__topbar__controls__control__label">Close</div>',
        '</div>',
        '</div>',
        '</div>',
        '<div class="mwl__navigation__previous">',
        window.MwlIcons.arrow_left,
        '</div>',
        '<div class="mwl__navigation__next">',
        window.MwlIcons.arrow_right,
        '</div>',
        '<div class="mwl__slider">',
        // Images will go here
        '</div>',
        '<div class="mwl__metadata">',
        '<h2></h2>',
        '<p class="short-description visible"></p>',
        '<p class="full-description"></p>',
        '<div class="exifs"></div>',
        '</div>',
        thumbnails_navigation_markup,
        '</div>'
      ]
      lightbox_markup = lightbox_markup.join('')

      $('body').append(lightbox_markup)
    }

    /**
     * Reads the page to add the mwl-img class to images matching selector/anti-selector
     */
    this.scrapThePage = function () {
      const self = this
      const $potential_images = $(self.settings.selector).find('img')

      window.createMwlLog('log', 'Scraping the page.')

      if (self.settings.anti_selector) {
        self.settings.anti_selector += ', .leaflet-tile, .leaflet-google-mutant'
      } else {
        self.settings.anti_selector += '.leaflet-tile, .leaflet-google-mutant'
      }

      let index = 0
      $potential_images.each(function () {
        if ($(this).parents(self.settings.anti_selector).length == 0 && !$(this).is(self.settings.anti_selector)) {
          $(this).addClass('mwl-img').attr('mwl-index', index)
          index++
        }
      })

      self.images_number = $('.mwl-img').length
    }

    this.imagesHaveChanged = function () {
      const self = this
      if (this.settings === undefined) {
        return
      }
      let images_have_changed = false
      // Check if an mwl-img has disapeared
      this.images.forEach(function (image, index) {
        if ($('.mwl-img[mwl-index=' + index + ']').length <= 0) {
          images_have_changed = true
        }
      })
      // Check if a new image eligible for mwl has been added
      const $potential_images = $(self.settings.selector).find('img')

      if (self.settings.anti_selector) {
        self.settings.anti_selector += ', .leaflet-tile, .leaflet-google-mutant'
      } else {
        self.settings.anti_selector += '.leaflet-tile, .leaflet-google-mutant'
      }

      $potential_images.each(function () {
        if ($(this).parents(self.settings.anti_selector).length == 0 && !$(this).is(self.settings.anti_selector)) {
          if (!$(this).hasClass('mwl-img')) {
            images_have_changed = true
          }
        }
      })
      return images_have_changed
    }

    /**
     * Create an MwlImage object for each images.
     */
    this.retrieveImagesData = function () {
      const self = this
      const $images = $('.mwl-img')

      self.images = []

      $images.each(function () {
        const $image = $(this)
        // The image used in the thumbnail
        let current_src = $image.prop('currentSrc')
        // Check if image is a lazy-loaded one, not yet loaded
        if ($image.hasClass('mgl-lazy') && !$image.hasClass('lazyloaded')) {
          current_src = false
        }

        if ($image.attr('data-mwl-img-id') && mwl_data && mwl_data[$image.attr('data-mwl-img-id')].success) {
          const image_data = mwl_data[$image.attr('data-mwl-img-id')]

          if (image_data.data.gps.split(",").length > 1) {
            image_data.gps = {
              lat: parseFloat(image_data.data.gps.split(",")[0]),
              lng: parseFloat(image_data.data.gps.split(",")[1])
            }
          } else {
            image_data.gps = {
              lat: "N/A",
              lng: "N/A"
            }
          }

          // Handled by the server-side
          // if (image_data.data.date) {
          //   image_data.data.date = new Date(image_data.data.date).toLocaleString('en-US')
          // }

          const data = {
            id: image_data.data.id,
            index: parseInt($image.attr('mwl-index')),
            exists: true,
            img_low_res_src: current_src,
            img_src: encodeURI(image_data.file),
            img_srcset: image_data.file_srcset,
            img_sizes: image_data.file_sizes,
            img_dimensions: image_data.dimension,
            img_orientation: image_data.dimension.width > image_data.dimension.height ? "landscape" : "portrait",
            img_exifs: image_data.data,
            img_gps: image_data.gps
          }

          self.images.push(new MwlImage(data))
        }
        else {
          let img_src = ''
          if ($image.hasClass('mgl-lazy')) {
            img_src = $image.attr('mgl-src')
          }
          if ($image.attr('data-lazy-src')) {
            img_src = $image.attr('data-lazy-src')
          }
          if (!img_src) {
            img_src = $image.attr('src')
          }

          let img_srcset = ''
          if ($image.attr('data-lazy-srcset')) {
            img_srcset = $image.attr('data-lazy-srcset')
          }
          if (!img_srcset) {
            img_srcset = $image.attr('srcset')
          }

          let img_sizes = ''
          if ($image.attr('data-lazy-sizes')) {
            img_sizes = $image.attr('data-lazy-sizes')
          }
          if (!img_sizes) {
            img_sizes = $image.attr('sizes')
          }

          const data = {
            exists: true,
            index: parseInt($image.attr('mwl-index')),
            img_low_res_src: current_src,
            img_src: img_src,
            img_srcset: img_srcset,
            img_sizes: img_sizes,
            img_dimensions: false,
            img_orientation: 'landscape',
            img_exifs: {
              title: '',
              caption: '',
              camera: 'N/A',
              lens: 'N/A',
              aperture: 'N/A',
              shutter_speed: 'N/A',
              iso: 'N/A',
              focal_length: 'N/A',
              date: 'N/A'
            }
          }
          self.images.push(new MwlImage(data))
        }
      })
    }

    this.clearSlider = function (index) {
      // let's get the array of images in slider
      const current_slides = []
      const $mwl_slider = $('.mwl .mwl__slider')
      $mwl_slider.find('.mwl__slider__image').each(function () {
        current_slides.push(parseInt($(this).attr('data-index')))
      })

      // now we shall get the position of the current image
      const margin = 4

      const current_position = current_slides.indexOf(index)

      let slides_to_be_removed_before = []
      if (current_position - margin > 0) {
        slides_to_be_removed_before = current_slides.slice(0, current_position - margin)
      }

      let slides_to_be_removed_after = []
      slides_to_be_removed_after = current_slides.slice(current_position + margin)

      const slides_to_be_removed = slides_to_be_removed_before.concat(slides_to_be_removed_after)
      slides_to_be_removed.forEach(function (i) {
        $mwl_slider.find('.mwl__slider__image[data-index=' + i + ']').remove()
      })
    }

    this.populateSlider = function (index) {
      const self = this
      const $mwl_slider = $('.mwl .mwl__slider')

      // let's determine all the indexes we want to add to the slide.
      const margin = 2
      const indexes_to_add = []
      // next ones
      for (let i = index; i <= index + margin; i++) {
        // check if there is an image of this index
        if (self.images[i]) {
          // check if index isn't in the slider already
          if ($mwl_slider.find('.mwl__slider__image[data-index=' + i + ']').length <= 0) {
            indexes_to_add.push(i)
          }
        }
      }
      // previous ones
      for (let i = index - 1; i >= index - margin; i--) {
        // check if there is an image of this index
        if (self.images[i]) {
          // check if index isn't in the slider already
          if ($mwl_slider.find('.mwl__slider__image[data-index=' + i + ']').length <= 0) {
            indexes_to_add.push(i)
          }
        }
      }
      indexes_to_add.sort(function (a, b) { return a - b })

      // Now, let's add these slides to our slider.
      indexes_to_add.forEach(function (i) {
        let noMagnificationClass = ''
        if (!self.settings.magnification.enabled) {
          noMagnificationClass = 'no-magnification'
        }
        let image_markup = [
          '<div class="mwl__slider__image ', noMagnificationClass, '" data-index="', i, '">',
          '<div class="mwl__slider__image__thumbnail">',
          '<div class="mwl__slider__image__thumbnail__low-res-container">',
          '<img class="mwl__slider__image__thumbnail__low-res">',
          '</div>',
          '<img class="mwl__slider__image__thumbnail__high-res" style="opacity: 0">',
          '<div id="image-map-', i, '" class="image-map"></div>',
          '</div>',
          '</div>'
        ]

        image_markup = image_markup.join('')
        const $image_markup = $(image_markup)
        const $high_res_image = $image_markup.find('img.mwl__slider__image__thumbnail__high-res')
        const $low_res_image = $image_markup.find('img.mwl__slider__image__thumbnail__low-res')
        const image = self.images[i]

        // let's get the low-res source
        if (settings.low_res_placeholder) {
          const used_src = $('.mwl-img[mwl-index=' + i + ']').prop('currentSrc')
          $low_res_image.attr('src', used_src)
        }

        $high_res_image.attr('src', image.img_src)
        $high_res_image.addClass(image.img_orientation)

        if (image.img_srcset && image.img_sizes) {
          $high_res_image.attr('srcset', image.img_srcset)
          $high_res_image.attr('sizes', image.img_sizes)
        }


        $high_res_image.on('load', function () {
          $('.mwl__slider__image[data-index=' + i + '] .mwl__slider__image__thumbnail').addClass('high-res-loaded')
        })


        const current_slides = []
        $mwl_slider.find('.mwl__slider__image').each(function () {
          current_slides.push(parseInt($(this).attr('data-index')))
        })
        current_slides.push(i)
        current_slides.sort(function (a, b) { return a - b })

        const ideal_position = current_slides.indexOf(i)

        if (ideal_position <= 0) {
          $mwl_slider.prepend($image_markup)
        } else {
          $mwl_slider.find('.mwl__slider__image').eq(ideal_position - 1).after($image_markup)
        }

      })
    }

    /**
     * Hides images metadatas to only leave the image itself.
     */
    this.extendLightbox = function () {
      $('.mwl').addClass('extended')
      $('.mwl__topbar__controls__control--fullscreen').addClass('hidden')
      $('.mwl__topbar__controls__control--shrink').removeClass('hidden')
    }

    /**
     * Shows images metadatas.
     */
    this.shrinkLightbox = function () {
      $('.mwl').removeClass('extended')
      $('.mwl__topbar__controls__control--fullscreen').removeClass('hidden')
      $('.mwl__topbar__controls__control--shrink').addClass('hidden')
    }

    this.getIndexById = function (id) {
      const self = this
      let index = false
      self.images.forEach(function (image) {
        if (image.id == id) {
          index = image.index
        }
      })
      return index
    }

    /**
     * Opens the lightbox at a specific index
     */
    this.openLightbox = function (index = 0, animation = true) {
      const self = this
      $('.mwl').addClass('visible')
      $('.mwl').attr('data-index', index)

      self.populateSlider(index)

      // get position of current image in the slider
      const current_index_position = $('.mwl__slider__image').index($('.mwl__slider__image[data-index=' + index + ']'))

      // don't animate first opening
      $('.mwl .mwl__slider').removeClass('mwl-animate')
      $('.mwl .mwl__slider').css('transform', 'translate3d(' + (-current_index_position) * 100 + '%, 0, 0)')

      $('.mwl .mwl__slider__image').removeClass('visible')

      setTimeout(function () {
        $('.mwl .mwl__slider__image[data-index=' + index + ']').addClass('visible')
      }, 10)

      updateImageMetadata(this, index, animation, function () {
        updateImageSizing(self, index)
      })

      updateCurrentSlideCount(index)
      updateUrl(this, index)
      this.current_index = index
    }

    /**
     * Refresh lightbox, in case of DOM modification for instance.
     */
    this.refreshLightbox = function () {
      if ($('.mwl').hasClass('visible')) {
        this.openLightbox(this.current_index, false)
      }
    }

    /**
     * Unzoom the image
     */
    this.unzoom = function () {
      $('.mwl__slider__image__thumbnail img').removeClass('zoomed')
      $('.mwl__slider__image__thumbnail img').css('transform', 'translate3d(0,0,0) scale(1)')
    }

    /**
     * Hide and destroy map
     */
    this.destroyMap = function () {
      const self = this
      if ($('#image-map-' + self.current_index).hasClass('visible')) {
        self.toggleImageMap()
      }
      const $map_parent = $('#image-map-' + self.current_index).parent()
      $('#image-map-' + self.current_index).remove()
      $map_parent.append('<div id="image-map-' + self.current_index + '" class="image-map"></div>')
    }

    /**
     * Closes the lightbox
     */
    this.closeLightbox = function () {
      const self = this

      // Unzoom
      self.unzoom()

      // Hide map
      self.destroyMap()

      // Stop slideshow
      self.stopInterval()

      $('.mwl').removeClass('visible')
      $('.mwl .mwl__slider').removeClass('mwl-animate')
      $('.mwl .mwl__slider img').css('opacity', 0)

      updateUrl(this, false)
    }

    /**
     * Slide to a specific index
     */
    this.slideTo = function (index) {
      const self = this
      $('.mwl').attr('data-index', index)
      $('.mwl .mwl__slider').addClass('mwl-animate')
      $('.mwl .mwl__slider__image').removeClass('visible')

      self.populateSlider(index)

      const new_index_position = $('.mwl__slider__image').index($('.mwl__slider__image[data-index=' + index + ']'))

      $('.mwl .mwl__slider').css('transform', 'translate3d(' + (-new_index_position) * 100 + '%, 0, 0)')
      updateImageMetadata(this, index, false, function () {
        $('.mwl .mwl__slider__image[data-index=' + index + ']').addClass('visible')
        updateImageSizing(self, index)
      })
      updateCurrentSlideCount(index)
      updateUrl(this, index)

      // Unzoom
      self.unzoom()

      // Hide map
      self.destroyMap()

      self.current_index = index
    }

    /**
     * Slides to the previous image
     */
    this.goToPrev = () => {
      let prev_index = this.current_index - 1
      if (this.current_index == 0) {
        prev_index = this.images.length - 1
      }
      this.slideTo(prev_index)
      this.current_index = prev_index
    }

    /**
     * Slides to the next image
     */
    this.goToNext = () => {
      let next_index = this.current_index + 1
      if (this.current_index == this.images.length - 1) {
        next_index = 0

      }
      this.slideTo(next_index)
      this.current_index = next_index
    }

    /**
     * Set all the event listeners ( esp. controls )
     */
    this.setListeners = function () {
      const controller = this

      /**
       * Resizing images when window size changes
       */
      $(window).on('resize', function () {
        if ($('.mwl').hasClass('visible')) {
          updateImageSizing(controller, controller.current_index)
        }
      })

      /**
       * Close Button
       */
      $(document).on('click', '.mwl__topbar__controls__control--close', function () {
        controller.closeLightbox()
      })

      /**
       * Close when click outside anything in lightbox
       */
      $(document).on('click', '.mwl.visible', function (e) {
        if ($(e.target).is('.mwl__topbar') || $(e.target).is('.mwl__slider__image__thumbnail') || $(e.target).is('.mwl__slider__image__thumbnail__low-res-container') || $(e.target).is('.mwl .exifs')) {
          controller.closeLightbox()
        }
      })

      /**
       * Navigation Arrows Click
       */
      $(document).on('click', '.mwl__navigation__previous', function () {
        controller.goToPrev()
      })

      $(document).on('click', '.mwl__navigation__next', function () {
        controller.goToNext()
      })

      /**
       * Navigation with keyboard arrows
       */
      $(document).keydown(function (e) {
        if ($('.mwl').hasClass('visible')) {
          switch (e.which) {
            case 27: // esc
              controller.closeLightbox()
              break

            case 37: // left
              controller.goToPrev()
              break

            case 39: // right
              controller.goToNext()
              break

            default: return
          }
        }
      })
      /**
       * Extend view
       */
      $(document).on('click', '.mwl__topbar__controls__control--fullscreen', function () {
        controller.extendLightbox()
      })

      /**
       * Shrink view
       */
      $(document).on('click', '.mwl__topbar__controls__control--shrink', function () {
        controller.shrinkLightbox()
      })

      /**
       * Autoplay Start
       */
      $(document).on('click', '.mwl__topbar__controls__control--play', function () {
        controller.startInterval()
      })

      /**
       * Autoplay Stop
       */
      $(document).on('click', '.mwl__topbar__controls__control--pause', function () {
        controller.stopInterval()
      })

      /**
       * Toggle Map
       */
      $(document).on('click', '.mwl__topbar__controls__control--map', function () {
        controller.toggleImageMap()
      })

      /**
       * Download Image
       */
      $(document).on('click', '.mwl__topbar__controls__control--download', function () {
        controller.downloadImage()
      })

      /**
       * Image zoom
       */
      $(document).on('click', '.mwl__slider__image__thumbnail img.mwl__slider__image__thumbnail__high-res', function (e) {
        if (controller.settings.magnification.enabled) {
          if (!$(this).hasClass('swiping')) {
            const current_index = $('.mwl').attr('data-index')
            if ($(this).hasClass('zoomed')) {
              $(this).removeClass('zoomed')
              $(this).css('transform', 'translate3d(0,0,0) scale(1)')
            } else {
              $(this).addClass('zoomed')
              const current_index_position = $('.mwl__slider__image').index($('.mwl__slider__image[data-index=' + current_index + ']'))
              const sliderOffsetLeft = current_index_position * $(window).outerWidth()
              const scrollTop = $(window).scrollTop()
              const posX = $(this).position().left - sliderOffsetLeft,
                posY = $(this).position().top + scrollTop
              const relativeX = e.pageX - posX
              const relativeY = e.pageY - posY
              const relativeToCenterX = relativeX - ($(this).outerWidth() / 2)
              const relativeToCenterY = relativeY - ($(this).outerHeight() / 2)

              $(this).css('transform', 'translate3d(' + -relativeToCenterX * 1.5 + 'px,' + -relativeToCenterY * 1.5 + 'px,0) scale(1.5)')
            }
          }
        }
      })

      /**
       * Toggle Full Description
       */
      $(document).on('click', '.toggle-full-description', function () {

        const $full_description = $('.mwl__metadata .full-description')
        const $short_description = $('.mwl__metadata .short-description')

        if ($full_description.hasClass('visible')) {
          $full_description.removeClass('visible')
          $short_description.addClass('visible')
        } else {
          $full_description.addClass('visible')
          $short_description.removeClass('visible')
        }
      })

      controller.setSwipeListener()
    }

    /**
     * Slider swipe
     */
    this.setSwipeListener = () => {
      const controller = this

      let threshold = 150
      if ($(window).outerWidth() < 700) {
        threshold = 50
      }

      $('.mwl__slider').swipe({
        swipeStatus: function (event, phase, direction, distance) {
          if (!$(event.target).is('.image-map')) {
            if (phase == 'start') {
              const translate_matrix = $('.mwl__slider').css('transform').replace(/[^0-9\-.,]/g, '').split(',')
              window.translate_x = parseFloat(translate_matrix[12] || translate_matrix[4])
            }
            if (phase != "cancel" && phase != "end") {
              if (distance > 2) {
                $('.mwl__slider__image__thumbnail img').addClass('swiping')
              }
              if (direction == 'left') {
                $('.mwl .mwl__slider').removeClass('mwl-animate')
                $('.mwl .mwl__slider').css('transform', 'translate3d(' + (window.translate_x - distance) + 'px, 0, 0)')
              }
              if (direction == 'right') {
                $('.mwl .mwl__slider').removeClass('mwl-animate')
                $('.mwl .mwl__slider').css('transform', 'translate3d(' + (window.translate_x + distance) + 'px, 0, 0)')
              }
            }
            if (phase == 'end') {
              if (direction == 'left') {
                controller.goToNext()
              }
              if (direction == 'right') {
                controller.goToPrev()
              }
              setTimeout(function () {
                $('.mwl__slider__image__thumbnail img').removeClass('swiping')
              }, 100)
            }
            if (phase == 'cancel') {
              $('.mwl .mwl__slider').addClass('mwl-animate')
              $('.mwl .mwl__slider').css('transform', 'translate3d(' + window.translate_x + 'px, 0, 0)')
              event.preventDefault()
              event.stopPropagation()
              setTimeout(function () {
                $('.mwl__slider__image__thumbnail img').removeClass('swiping')
              }, 100)
            }
          }
        },
        threshold: threshold,
        maxTimeThreshold: 5000,
        fingers: 'all'
      })
    }

    /**
     * Downloads the current image
     */
    this.downloadImage = function () {
      const self = this
      const href = self.images[self.current_index].img_src
      const anchor = document.createElement('a')
      anchor.href = href
      anchor.download = href.split(/[\\/]/).pop()
      document.body.appendChild(anchor)
      anchor.click()
    }

    /**
     * Starts the slideshow
     */
    this.startInterval = function () {
      const self = this
      $('.mwl__topbar__controls__control--play').addClass('hidden')
      $('.mwl__topbar__controls__control--pause').removeClass('hidden')
      self.autoplay_interval = setInterval(function () {
        self.goToNext()
      }, self.settings.slideshow.slide_duration)
    }

    /**
     * Stops the slideshow
     */
    this.stopInterval = function () {
      $('.mwl__topbar__controls__control--pause').addClass('hidden')
      $('.mwl__topbar__controls__control--play').removeClass('hidden')
      clearInterval(this.autoplay_interval)
    }

    /**
     * Toggles the map
     */
    this.toggleImageMap = () => {

      // Unzoom
      this.unzoom()

      const $map = $('#image-map-' + this.current_index)
      const map_id = $map.attr('id')
      const $image_container = $('.mwl__slider__image[data-index=' + this.current_index + ']')
      const $image = $image_container.find('img.mwl__slider__image__thumbnail__high-res')
      const image = this.images[this.current_index]
      if (!$map.hasClass('visible')) {
        $map.addClass('visible')
        $map.css('width', $image.outerWidth() + 'px')
        $map.css('height', $image.outerHeight() + 'px')
        window.mwlInitMap(map_id, image)
        $('.mwl__slider').swipe('destroy')
      } else {
        $map.removeClass('visible')
        this.destroyMap()
        $map.css('width', 0)
        $map.css('height', 0)
        this.setSwipeListener()
      }
    }

    /**
     * Destroy the lightbox
     */
    this.selfDestroy = function () {
      $('.mwl').remove()
      this.images = []
    }

  }

})
