/*
  This file is linking the front-end events with the JS logic in mwl-core.js
*/

/* global mwl MwlController navigator */

jQuery(document).ready(function ($) {

  /**
   * Log variable and related helpers
   */
  window.mwl_logs = []
  window.createMwlLog = function (type, message) {
    window.mwl_logs.push({
      type: type,
      message: message
    })
    return
  }
  window.displayMwlLog = function () {
    window.mwl_logs.forEach(function (log) {
      switch (log.type) {
        case 'error':
          console.error(log.message)
          break
        case 'log':
          // eslint-disable-next-line no-console
          console.log(log.message)
          break
      }
    })
  }

  /**
   * Retrieving all settings from PHP
   */
  const settings = {
    selector: mwl.settings.selector,
    anti_selector: mwl.settings.anti_selector,
    theme: mwl.settings.theme,
    slideshow: {
      enabled: mwl.settings.slideshow.enabled,
      slide_duration: mwl.settings.slideshow.timer
    },
    // Still in development
    thumbnails_navigation: {
      enabled: false,
      height: 200
    },
    preloading: mwl.settings.preloading, // not in use
    magnification: {
      enabled: mwl.settings.magnification
    },
    map: mwl.settings.map,
    caption_source: mwl.settings.caption_source,
    low_res_placeholder: mwl.settings.low_res_placeholder,
    deep_linking: mwl.settings.deep_linking,
    social_sharing: true,
    infos_to_display: {
      title: mwl.settings.exif.title,
      caption: mwl.settings.exif.caption,
      camera: mwl.settings.exif.camera,
      date: mwl.settings.exif.date,
      lens: mwl.settings.exif.lens,
      shutter_speed: mwl.settings.exif.shutter_speed,
      aperture: mwl.settings.exif.aperture,
      focal_length: mwl.settings.exif.focal_length,
      iso: mwl.settings.exif.iso
    },
    download: {
      enabled: mwl.settings.download_link
    },
    right_click_protection: mwl.settings.right_click_protection
  }

  /**
   * Initiate the meow lightbox controller
   */
  const mwlController = new MwlController(settings)

  /**
   * After a small timeout to ensure most things are loaded.
   */
  setTimeout(function () {
    mwlController.scrapThePage()
    mwlController.retrieveImagesData()
    mwlController.writeInitialMarkup()
    mwlController.setListeners()

    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        // Chrome
      } else {
        // Safari
        $('.mwl').addClass('safari-browser')
      }
    }

    if (window.location.href.indexOf('#mwl-') > 0) {
      const raw_key = window.location.href.match(/(#mwl-)([0-9])+/gm)
      const key = parseInt(raw_key[0].match(/([0-9])+/gm)[0])
      if (mwlController.getIndexById(key) !== false) {
        mwlController.openLightbox(mwlController.getIndexById(key))
      }
    }

    /**
     * Listen to post-load event, meaning content has been added through AJAX
     */
    $(document.body).on('post-load', function () {
      if (mwlController.imagesHaveChanged()) {
        window.createMwlLog('log', 'post-load event detected, reinitializing lightbox.')
        mwlController.scrapThePage()
        mwlController.retrieveImagesData()
        mwlController.refreshLightbox()
      }
    })
  }, 300)

  /**
   * Opening the lightbox when an .mwl-img is clicked
   */
  $(document).on('click', '.mwl-img', function (e) {
    window.createMwlLog('log', '.mwl-img has been clicked, opening the lightbox.')
    e.preventDefault()
    const index = parseInt($(this).attr('mwl-index'))
    mwlController.openLightbox(index)
  })

  /**
   * Image Right Click protection
   */
  $(document).on('contextmenu', '.mwl__slider__image__thumbnail img', function (e) {
    if (mwlController.settings.right_click_protection) {
      window.createMwlLog('log', 'protected the right click on an image.')
      e.preventDefault()
    }
  })

  setInterval(function () {
    //$('.mwl-img[mwl-index=0]').remove()
    //$('.entry-content').append('<img src="https://i.pinimg.com/originals/26/76/3d/26763d481172f5dc599d151570b38ded.png" style="height: 50px; width: auto; display: inline-block;">')
  }, 5000)

})
